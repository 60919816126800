interface IconProps {
  width?: string,
  height?: string,
  color?: string,
  className?: string
  style?: React.CSSProperties
}

export const GithubIcon = (props: IconProps) => (
  <svg width={props.width || "34px"} height={props.height || "33px"} viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 0C7.6075 0 0 7.38375 0 16.5C0 23.8013 4.86625 29.9681 11.6237 32.1544C12.4737 32.2988 12.7925 31.8038 12.7925 31.3706C12.7925 30.9788 12.7713 29.6794 12.7713 28.2975C8.5 29.0606 7.395 27.2869 7.055 26.3587C6.86375 25.8844 6.035 24.42 5.3125 24.0281C4.7175 23.7188 3.8675 22.9556 5.29125 22.935C6.63 22.9144 7.58625 24.1312 7.905 24.6262C9.435 27.1219 11.8788 26.4206 12.8563 25.9875C13.005 24.915 13.4513 24.1931 13.94 23.7806C10.1575 23.3681 6.205 21.945 6.205 15.6337C6.205 13.8394 6.86375 12.3544 7.9475 11.1994C7.7775 10.7869 7.1825 9.09562 8.1175 6.82687C8.1175 6.82687 9.54125 6.39375 12.7925 8.51813C14.1525 8.14688 15.5975 7.96125 17.0425 7.96125C18.4875 7.96125 19.9325 8.14688 21.2925 8.51813C24.5438 6.37313 25.9675 6.82687 25.9675 6.82687C26.9025 9.09562 26.3075 10.7869 26.1375 11.1994C27.2213 12.3544 27.88 13.8187 27.88 15.6337C27.88 21.9656 23.9062 23.3681 20.1238 23.7806C20.74 24.2962 21.2712 25.2863 21.2712 26.8331C21.2712 29.04 21.25 30.8137 21.25 31.3706C21.25 31.8038 21.5688 32.3194 22.4188 32.1544C29.1338 29.9681 34 23.7806 34 16.5C34 7.38375 26.3925 0 17 0Z" fill={props.color || "black"} />
  </svg>
)

export const ViewSiteIcon = (props: IconProps) => (
  <svg width={props.width || "31px"} height={props.height || "25px"} viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4998 0.312012L26.1071 0.603354C27.9814 0.654833 29.5317 2.03701 29.7448 3.84666C30.2166 7.85244 30.2166 11.8974 29.7448 15.9032C29.5317 17.7129 27.9814 19.095 26.1071 19.1465L18.0519 19.3678V22.2708H22.7915C23.3955 22.2708 23.8852 22.7465 23.8852 23.3333C23.8852 23.9201 23.3955 24.3958 22.7915 24.3958H8.20812C7.60406 24.3958 7.11437 23.9201 7.11437 23.3333C7.11437 22.7465 7.60406 22.2708 8.20812 22.2708H12.9477V19.3678L4.89239 19.1465C3.01811 19.095 1.46788 17.7129 1.25474 15.9032C0.782927 11.8974 0.782927 7.85244 1.25474 3.84666C1.46788 2.03701 3.01811 0.654833 4.89239 0.603354L15.4998 0.312012ZM15.4998 2.43786L4.95422 2.7275C4.16787 2.7491 3.51748 3.32899 3.42806 4.08822C2.97515 7.93349 2.97515 11.8164 3.42806 15.6617C3.51748 16.4209 4.16787 17.0008 4.95422 17.0224L15.4998 17.312L26.0453 17.0224C26.8317 17.0008 27.4821 16.4209 27.5715 15.6617C28.0244 11.8164 28.0244 7.93349 27.5715 4.08822C27.4821 3.32899 26.8317 2.7491 26.0453 2.7275L15.4998 2.43786Z" fill={props.color || "black"} />
  </svg>
)

export const GalleryIcon = (props: IconProps) => (
  <svg width={props.width || "20px"} height={props.height || "16px"} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.26753 0.658553C8.41658 0.384812 11.5834 0.384812 14.7325 0.658552L16.2427 0.789834C17.6878 0.915451 18.8585 2.0151 19.0742 3.44949C19.5278 6.4662 19.5278 9.53379 19.0742 12.5505C19.0345 12.8144 18.9625 13.067 18.8624 13.3042C18.796 13.4615 18.5912 13.4854 18.4764 13.3591L14.055 8.4955C13.8552 8.27575 13.5446 8.19458 13.2628 8.28849L10.732 9.1321L7.06055 5.00173C6.92332 4.84734 6.7285 4.75639 6.52203 4.75032C6.31556 4.74426 6.11572 4.82361 5.96967 4.96967L1.05554 9.88379C0.904302 10.035 0.646362 9.93877 0.634303 9.72522C0.516067 7.63135 0.613254 5.52834 0.925863 3.44949C1.14156 2.0151 2.31223 0.915451 3.7573 0.789834L5.26753 0.658553ZM12 5C12 4.17157 12.6716 3.5 13.5 3.5C14.3284 3.5 15 4.17157 15 5C15 5.82843 14.3284 6.5 13.5 6.5C12.6716 6.5 12 5.82843 12 5Z" fill={props.color || "black"} />
    <path d="M0.961334 12.0993C0.90598 12.1547 0.879686 12.2328 0.890712 12.3103C0.90211 12.3904 0.913827 12.4705 0.925863 12.5505C1.14156 13.9849 2.31223 15.0845 3.7573 15.2102L5.26753 15.3414C8.41658 15.6152 11.5834 15.6152 14.7325 15.3414L16.2427 15.2102C16.6674 15.1732 17.0683 15.0522 17.4283 14.8633C17.5647 14.7917 17.5876 14.6114 17.484 14.4974L13.3832 9.98651C13.3166 9.91326 13.2131 9.8862 13.1192 9.91751L10.7372 10.7115C10.4525 10.8064 10.1388 10.7225 9.93944 10.4983L6.64403 6.79094C6.54847 6.68343 6.38211 6.67854 6.2804 6.78025L0.961334 12.0993Z" fill={props.color || "black"} />
  </svg>
)

export const LinkedInIcon = (props: IconProps) => (
  <svg width={props.width || "20px"} height={props.height || "21px"} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.75 0.875C1.57639 0.875 0.625 1.82639 0.625 3C0.625 4.1736 1.57639 5.125 2.75 5.125C3.9236 5.125 4.875 4.1736 4.875 3C4.875 1.82639 3.9236 0.875 2.75 0.875Z" fill={props.color || "black"} />
    <path d="M0.75 6.875C0.680964 6.875 0.625 6.93096 0.625 7V20C0.625 20.069 0.680964 20.125 0.75 20.125H4.75C4.81904 20.125 4.875 20.069 4.875 20V7C4.875 6.93096 4.81904 6.875 4.75 6.875H0.75Z" fill={props.color || "black"} />
    <path d="M7.25 6.875C7.18096 6.875 7.125 6.93096 7.125 7V20C7.125 20.069 7.18096 20.125 7.25 20.125H11.25C11.319 20.125 11.375 20.069 11.375 20V13C11.375 12.5027 11.5725 12.0258 11.9242 11.6742C12.2758 11.3225 12.7527 11.125 13.25 11.125C13.7473 11.125 14.2242 11.3225 14.5758 11.6742C14.9275 12.0258 15.125 12.5027 15.125 13V20C15.125 20.069 15.181 20.125 15.25 20.125H19.25C19.319 20.125 19.375 20.069 19.375 20V11.3802C19.375 8.9535 17.2644 7.05499 14.8498 7.2745C14.1052 7.34219 13.369 7.52731 12.6815 7.82194L11.375 8.38186V7C11.375 6.93096 11.319 6.875 11.25 6.875H7.25Z" fill={props.color || "black"} />
  </svg>
)

export const EmailIcon = (props: IconProps) => (
  <svg width={props.width || "24px"} height={props.height || "24px"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.28918 4.90761C10.4238 4.63513 13.5762 4.63512 16.7108 4.90761L18.2211 5.03889C19.2264 5.12629 20.0875 5.72596 20.5337 6.58374C20.5907 6.69347 20.5479 6.82633 20.4428 6.89144L14.1768 10.7704C12.833 11.6023 11.1384 11.6197 9.77772 10.8157L3.46989 7.08833C3.36798 7.02811 3.3212 6.90415 3.36713 6.79505C3.77529 5.82548 4.69292 5.1333 5.77895 5.0389L7.28918 4.90761Z" fill={props.color || 'black'} />
    <path d="M3.362 8.76688C3.20603 8.67472 3.00703 8.7739 2.98885 8.95414C2.73512 11.4698 2.79653 14.0092 3.17308 16.5133C3.3716 17.8334 4.44901 18.8455 5.77895 18.9611L7.28918 19.0924C10.4238 19.3649 13.5762 19.3649 16.7108 19.0924L18.2211 18.9611C19.551 18.8455 20.6284 17.8334 20.8269 16.5133C21.2145 13.9363 21.2682 11.3217 20.9882 8.73428C20.9685 8.55276 20.7662 8.45541 20.6109 8.55151L14.9664 12.0458C13.1482 13.1713 10.8556 13.1949 9.01462 12.1071L3.362 8.76688Z" fill={props.color || 'black'} />
  </svg>
)

export const MainLogo = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.52 117.52" width={props.width || "24px"} height={props.height || "24px"}>
    <g>
      <path style={{fill: props.color || '#126ab3'}} d="M111,31.81A58.8,58.8,0,0,0,29.38,7.86Z"/>
      <path style={{fill: props.color || '#126ab3'}} d="M0,58.76c0,.77,0,1.54.05,2.31l20.19-4.31V14.39A58.6,58.6,0,0,0,0,58.76Z"/>
      <path style={{fill: props.color || '#126ab3'}} d="M38.2,113.82A58.82,58.82,0,0,0,107.25,92Z"/>
      <path style={{fill: props.color || '#126ab3'}} d="M20.24,67.45l-20-3.91a58.63,58.63,0,0,0,20,39.59Z"/>
      <path style={{fill: props.color || '#126ab3'}} d="M117.52,58.76a58.56,58.56,0,0,0-4.22-21.89L20.24,56.76V67.45l91,17.76A58.46,58.46,0,0,0,117.52,58.76Z"/>
    </g>
  </svg>
)

export const LeftChevron = (props: IconProps) => (
  <svg width={props.width || "7px"} height={props.height || "10px"} viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" style={{fill: props.color || 'black'}} clipRule="evenodd" d="M6.03033 0.46967C6.32322 0.762563 6.32322 1.23744 6.03033 1.53033L2.56066 5L6.03033 8.46967C6.32322 8.76256 6.32322 9.23744 6.03033 9.53033C5.73744 9.82322 5.26256 9.82322 4.96967 9.53033L0.96967 5.53033C0.676777 5.23744 0.676777 4.76256 0.96967 4.46967L4.96967 0.46967C5.26256 0.176777 5.73744 0.176777 6.03033 0.46967Z"/>
  </svg>
)

export const RightChevron = (props: IconProps) => (
  <svg width={props.width || "7px"} height={props.height || "10px"} viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" style={{fill: props.color || 'black'}} clipRule="evenodd" d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"/>
  </svg>
)

export const Checkmark = (props: IconProps) => (
  <svg width={props.width || "14px"} height={props.height || "10px"} viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" style={{fill: props.color || 'black'}} clipRule="evenodd" d="M13.0303 0.96967C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L6.03033 9.03033C5.73744 9.32322 5.26256 9.32322 4.96967 9.03033L0.96967 5.03033C0.676777 4.73744 0.676777 4.26256 0.96967 3.96967C1.26256 3.67678 1.73744 3.67678 2.03033 3.96967L5.5 7.43934L11.9697 0.96967C12.2626 0.676777 12.7374 0.676777 13.0303 0.96967Z"/>
  </svg>
)

export const LoadingOval = (props: IconProps) => (
  <svg width={props.width || "38px"} height={props.height || "38px"} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" style={{stroke: props.color || '#fff'}}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"/>
        </path>
      </g>
    </g>
  </svg>
)
